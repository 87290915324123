import { Tooltip, Typography } from '@mui/material';
import { useElementIsOverflowing } from '@watershed/shared-frontend/hooks/useElementIsOverflowing';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

export default function TruncatingTypographyWithTooltip({
  children,
  tooltip,
  tooltipProps,
  ...props
}: {
  children: ReactNode;
  component?: React.ElementType;
  tooltipProps?: Partial<ComponentPropsWithoutRef<typeof Tooltip>>;
  tooltip: NonNullable<ReactNode>;
} & Omit<ComponentPropsWithoutRef<typeof Typography>, 'noWrap'>) {
  const [ref, showTooltip] = useElementIsOverflowing();

  return (
    <Tooltip
      title={tooltip}
      disableHoverListener={!showTooltip}
      {...tooltipProps}
    >
      <Typography noWrap ref={ref} {...props}>
        {children}
      </Typography>
    </Tooltip>
  );
}
