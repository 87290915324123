import { BadInputError } from '../errors/BadInputError';
import {
  RESULT_KIND_KWH,
  RESULT_KIND_MMBTU_OF_ENERGY,
  RESULT_KIND_KG_OF_REFRIGERANT,
  RESULT_KIND_LAND_AREA,
  RESULT_KIND_EXPENDITURE_IN_USD,
  RESULT_KIND_LITERS_OF_WATER_USED,
  RESULT_KIND_LITERS_OF_WATER_CONSUMED,
  RESULT_KIND_LITERS_OF_WATER_RECYCLED,
  RESULT_KIND_LITERS_OF_WATER_STORED,
  RESULT_KIND_KG_OF_WASTE,
  RESULT_KIND_LITERS_OF_WATER_DISCHARGED,
  RESULT_KIND_KG_OF_SUBSTANCE_INFLOW,
  RESULT_KIND_KG_OF_SUBSTANCE_OUTFLOW,
  RESULT_KIND_KG_OF_MICROPLASTICS_INFLOW,
  RESULT_KIND_KG_OF_MICROPLASTICS_OUTFLOW,
  RESULT_KIND_GDP_IN_PPP_ADJUSTED_USD,
  RESULT_KIND_ATTRIBUTION_FACTOR,
} from './constants';

export const RESULT_KIND_IDS = [
  RESULT_KIND_KWH,
  RESULT_KIND_KG_OF_REFRIGERANT,
  RESULT_KIND_MMBTU_OF_ENERGY,
  RESULT_KIND_LAND_AREA,
  RESULT_KIND_EXPENDITURE_IN_USD,
  RESULT_KIND_LITERS_OF_WATER_USED,
  RESULT_KIND_LITERS_OF_WATER_CONSUMED,
  RESULT_KIND_LITERS_OF_WATER_RECYCLED,
  RESULT_KIND_LITERS_OF_WATER_STORED,
  RESULT_KIND_LITERS_OF_WATER_DISCHARGED,
  RESULT_KIND_KG_OF_WASTE,
  RESULT_KIND_KG_OF_SUBSTANCE_INFLOW,
  RESULT_KIND_KG_OF_SUBSTANCE_OUTFLOW,
  RESULT_KIND_KG_OF_MICROPLASTICS_INFLOW,
  RESULT_KIND_KG_OF_MICROPLASTICS_OUTFLOW,
  RESULT_KIND_GDP_IN_PPP_ADJUSTED_USD,
  RESULT_KIND_ATTRIBUTION_FACTOR,
] as const;

export type ResultKindId = (typeof RESULT_KIND_IDS)[number];

export type ResultKindDefinition = {
  id: ResultKindId;
  displayName: string;
  description: string;
  unit: string | null;
} & ( // sql columns representing this kind in PLR / melted evaluation. Null means the kind will not be written to PLRs.
  | {
      sqlQuantityField: string;
      sqlNamespacedVariableField: string;
    }
  | {
      sqlQuantityField: null;
      sqlNamespacedVariableField: null;
    }
);

// This is just a handy helper translating result kinds to some standard short names.
// These short names are useful for text fixtures and making sql field names
export const resultKindToShortName = new Map(
  Object.entries({
    [RESULT_KIND_KG_OF_WASTE]: 'waste',
    [RESULT_KIND_LITERS_OF_WATER_USED]: 'water_used',
    [RESULT_KIND_LITERS_OF_WATER_RECYCLED]: 'water_recycled',
    [RESULT_KIND_LITERS_OF_WATER_CONSUMED]: 'water_consumed',
    [RESULT_KIND_LITERS_OF_WATER_STORED]: 'water_stored',
    [RESULT_KIND_LITERS_OF_WATER_DISCHARGED]: 'water_discharged',
    [RESULT_KIND_KG_OF_SUBSTANCE_INFLOW]: 'substance_inflow',
    [RESULT_KIND_KG_OF_SUBSTANCE_OUTFLOW]: 'substance_outflow',
    [RESULT_KIND_KG_OF_MICROPLASTICS_INFLOW]: 'microplastics_inflow',
    [RESULT_KIND_KG_OF_MICROPLASTICS_OUTFLOW]: 'microplastics_outflow',
    [RESULT_KIND_KWH]: 'kwh',
  })
);

const standardQuantityField = (kind: ResultKindId, unit: 'kg' | 'liters') =>
  `quantity_${unit}_${resultKindToShortName.get(kind) ?? kind}`;

const standardNamespacedVariableField = (kind: ResultKindId) =>
  `${
    resultKindToShortName.get(kind) ?? kind
  }_emissions_model_namespaced_variable_name`;

const definitions: Array<ResultKindDefinition> = [
  {
    id: RESULT_KIND_KWH,
    displayName: 'kWh',
    description: 'Electricity or energy consumed by the process.',
    unit: 'kilowatt-hour',
    sqlQuantityField: 'kwh',
    sqlNamespacedVariableField:
      standardNamespacedVariableField(RESULT_KIND_KWH),
  },
  {
    id: RESULT_KIND_KG_OF_REFRIGERANT,
    displayName: 'Refrigerant mass',
    description: 'Refrigerant consumed by the process.',
    unit: 'kilogram',
    sqlQuantityField: null,
    sqlNamespacedVariableField: null,
  },
  {
    id: RESULT_KIND_MMBTU_OF_ENERGY,
    displayName: 'Thermal energy',
    description: 'Thermal energy consumed by the process.',
    unit: 'mmbtu',
    sqlQuantityField: null,
    sqlNamespacedVariableField: null,
  },
  {
    id: RESULT_KIND_LAND_AREA,
    displayName: 'Land area',
    description: 'Land area involved in the process.',
    unit: 'acre',
    sqlQuantityField: null,
    sqlNamespacedVariableField: null,
  },
  {
    id: RESULT_KIND_EXPENDITURE_IN_USD,
    displayName: 'Expenditure in USD',
    description: 'Expenditure standardized to USD.',
    unit: 'USD',
    sqlQuantityField: null,
    sqlNamespacedVariableField: null,
  },
  {
    id: RESULT_KIND_LITERS_OF_WATER_USED,
    displayName: 'Liters of water used',
    description: 'Liters of water used by the process.',
    unit: 'liter',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_LITERS_OF_WATER_USED,
      'liters'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_LITERS_OF_WATER_USED
    ),
  },
  {
    id: RESULT_KIND_LITERS_OF_WATER_CONSUMED,
    displayName: 'Liters of water consumed',
    description: 'Liters of water consumed by the process.',
    unit: 'liter',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_LITERS_OF_WATER_CONSUMED,
      'liters'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_LITERS_OF_WATER_CONSUMED
    ),
  },
  {
    id: RESULT_KIND_LITERS_OF_WATER_RECYCLED,
    displayName: 'Liters of water recycled',
    description: 'Liters of water recycled by the process.',
    unit: 'liter',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_LITERS_OF_WATER_RECYCLED,
      'liters'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_LITERS_OF_WATER_RECYCLED
    ),
  },
  {
    id: RESULT_KIND_LITERS_OF_WATER_STORED,
    displayName: 'Liters of water stored',
    description: 'Liters of water stored by the process.',
    unit: 'liter',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_LITERS_OF_WATER_STORED,
      'liters'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_LITERS_OF_WATER_STORED
    ),
  },
  {
    id: RESULT_KIND_LITERS_OF_WATER_DISCHARGED,
    displayName: 'Liters of water discharged',
    description: 'Liters of water discharged by the process.',
    unit: 'liter',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_LITERS_OF_WATER_DISCHARGED,
      'liters'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_LITERS_OF_WATER_DISCHARGED
    ),
  },
  {
    id: RESULT_KIND_KG_OF_WASTE,
    displayName: 'Waste',
    description: 'Waste generated by the process',
    unit: 'kilogram',
    sqlQuantityField: standardQuantityField(RESULT_KIND_KG_OF_WASTE, 'kg'),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_KG_OF_WASTE
    ),
  },
  {
    id: RESULT_KIND_KG_OF_SUBSTANCE_INFLOW,
    displayName: 'Substance inflow',
    description: 'Substance inflows by the process',
    unit: 'kilogram',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_KG_OF_SUBSTANCE_INFLOW,
      'kg'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_KG_OF_SUBSTANCE_INFLOW
    ),
  },
  {
    id: RESULT_KIND_KG_OF_SUBSTANCE_OUTFLOW,
    displayName: 'Substance outflow',
    description: 'Substance outflows by the process',
    unit: 'kilogram',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_KG_OF_SUBSTANCE_OUTFLOW,
      'kg'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_KG_OF_SUBSTANCE_OUTFLOW
    ),
  },
  {
    id: RESULT_KIND_KG_OF_MICROPLASTICS_INFLOW,
    displayName: 'Microplastics inflow',
    description: 'Microplastics inflows by the process',
    unit: 'kilogram',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_KG_OF_MICROPLASTICS_INFLOW,
      'kg'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_KG_OF_MICROPLASTICS_INFLOW
    ),
  },
  {
    id: RESULT_KIND_KG_OF_MICROPLASTICS_OUTFLOW,
    displayName: 'Microplastics outflow',
    description: 'Microplastics outflows by the process',
    unit: 'kilogram',
    sqlQuantityField: standardQuantityField(
      RESULT_KIND_KG_OF_MICROPLASTICS_OUTFLOW,
      'kg'
    ),
    sqlNamespacedVariableField: standardNamespacedVariableField(
      RESULT_KIND_KG_OF_MICROPLASTICS_OUTFLOW
    ),
  },
  {
    id: RESULT_KIND_GDP_IN_PPP_ADJUSTED_USD,
    displayName: 'PPP-adjusted GDP',
    description: 'GDP in current international USD (PPP-adjusted)',
    unit: 'USD',
    sqlQuantityField: null,
    sqlNamespacedVariableField: null,
  },
  {
    id: RESULT_KIND_ATTRIBUTION_FACTOR,
    displayName: 'Attribution factor',
    description:
      'Portion of emissions attributed to current organization as scope 3.15 emissions',
    unit: null,
    sqlQuantityField: null,
    sqlNamespacedVariableField: null,
  },
];

const definitionsMap: Map<ResultKindId, ResultKindDefinition> = new Map();
definitions.forEach((resultKindDef) =>
  definitionsMap.set(resultKindDef.id, resultKindDef)
);

/**
 * Gets the definition of an result kind, throwing an error if the kind
 * doesn't exist.
 */
export function getResultKindDefinition(
  name: ResultKindId
): ResultKindDefinition {
  const def = definitionsMap.get(name);
  if (!def) {
    throw new BadInputError(`No result kind named "${name}"`);
  }
  return def;
}

/**
 * Returns all result kinds.
 */
export function getAllResultKindDefinitions(): Array<ResultKindDefinition> {
  return definitions;
}
