import keyBy from 'lodash/keyBy';
import { Cache, cacheExchange } from '@urql/exchange-graphcache';
import {
  GlobalEngagementTaskConfigsAdminDocument,
  OrgSpecificEngagementTaskConfigsAdminDocument,
  ReviewItemsDialogDocument,
  GetCdpIdMappingsDocument,
} from '@watershed/shared-frontend/generated/urql';
import {
  GQCreateEngagementTaskConfigAdminMutation,
  GQDeleteEngagementTaskConfigAdminMutation,
  GQGlobalEngagementTaskConfigsAdminQuery,
  GQOrgSpecificEngagementTaskConfigsAdminQuery,
  GQMutationCreateReportConfigFromSrcArgs,
  GQRemoveUserAdminVersionMutationVariables,
  GQUpdateReportConfigFromAdminTableMutation,
  GQReviewPrepublicationTestResultsInput,
  GQGlobalEngagementTaskConfigsAdminQueryVariables,
} from '@watershed/shared-universal/generated/graphql';
import {
  GQBulkImportMarketplaceAllocationInstructionsInput,
  GQCreateMarketplaceAllocationInstructionInput,
  GQDeleteFinancialsReviewItemMutation,
  GQDeleteFinancialsReviewItemMutationVariables,
  GQDeleteMarketplaceDeveloperCacheUpdateQuery,
  GQDeleteMarketplaceDeveloperMutation,
  GQDeleteMarketplaceDocumentMutation,
  GQDeleteReportQuestionContainerMutation,
  GQDeleteReportQuestionMutation,
  GQFrozenDatasourceSummary,
  GQIndustrySector,
  GQMutationAddOrgPointOfContactArgs,
  GQMutationArchiveFootprintSnapshotArgs,
  GQMutationArchivePipelineResultArgs,
  GQMutationCreateActivityDataTableArgs,
  GQMutationCreateApiKeyArgs,
  GQMutationCreateReportQuestionContainerArgs,
  GQMutationCreateUserAdminVersionArgs,
  GQMutationDeleteOrgPointOfContactArgs,
  GQMutationDeleteReportQuestionArgs,
  GQMutationDeleteReportQuestionContainerArgs,
  GQMutationSaveEmissionsModelDraftArgs,
  GQMutationSetFootprintVersionKindArgs,
  GQMutationUpdateActivityDataTableArgs,
  GQRemoveUserAdminVersionMutation,
  GQReviewItemsDialogQuery,
  GQSimpleTimeseries,
  GQGetCdpIdMappingsQuery,
  GQUpsertCdpColumnMappingsMutation,
  GQUpsertCdpQuestionMappingsMutation,
} from '@watershed/shared-universal/generated/graphql';
import must from '@watershed/shared-universal/utils/must';
import { Exchange, gql } from 'urql';

type BasicIntrospectionQuery = { __schema: any };

function invalidateAllTopLevelQueriesForNode(
  cache: Cache,
  topLevelQueryName: string
) {
  const allFields = cache.inspectFields('Query');
  const queries = allFields.filter((x) => x.fieldName === topLevelQueryName);
  for (const query of queries) {
    const { fieldName, arguments: variables } = query;
    cache.invalidate('Query', fieldName, variables || undefined);
  }
}

function invalidateAllTopLevelQueriesForOrgId(
  cache: Cache,
  topLevelQueryName: string,
  orgId: string | null
) {
  const allFields = cache.inspectFields('Query');
  const queries = allFields.filter((x) => x.fieldName === topLevelQueryName);
  for (const query of queries) {
    const { fieldName, arguments: variables } = query;
    if (
      variables === null ||
      ('orgId' in variables && orgId === variables.orgId) ||
      ('orgId' in variables && orgId === null)
    ) {
      cache.invalidate('Query', fieldName, variables || undefined);
    }
  }
}

function invalidateReportConfigObject(cache: Cache, id: string) {
  if (id.startsWith('repqc_')) {
    cache.invalidate({
      __typename: 'ReportQuestionContainer',
      id,
    });
  } else if (id.startsWith('repq_')) {
    cache.invalidate({
      __typename: 'ReportQuestion',
      id,
    });
  } else if (id.startsWith('rep_')) {
    cache.invalidate({
      __typename: 'ReportConfig',
      id,
    });
  }
}

const cache: (schema: BasicIntrospectionQuery) => Exchange = (schema) =>
  cacheExchange({
    keys: {
      // Returning null means graphcache will skip normalizing these types.
      // See https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/
      EmissionsModelEditorResolvedParameters: (data) => null,
      Vendor: (data) => null,
      PipelineOutputSummaryEmission: (data) => null,
      PipelineOutputSummary: (data) => null,
      FootprintSnapshotSummaryEmission: (data) => null,
      FootprintSnapshotSummary: (data) => null,
      ValidationWarning: (data) => null,

      FilterExpressionGroup: (data) => null,
      FilterExpressionPrimitive: (data) => null,

      PlanVariables: (data) => null,
      PlanCarbonFund: (data) => null,

      BenchmarkBreakdownDataPoint: (data) => null,

      UserUploadedTableDataPreview: (data) => null,
      UserUploadedTableDataPreviewSchema: (data) => null,
      UserUploadedTableDataPreviewSchemaField: (data) => null,
      UserUploadedTableCell: (data) => null,

      UtilityUsageData: (data) => null,

      UserUploadedTableSchemaColumn: (data) => null,
      UserUploadedTableParseConfig: (data) => null,

      PipelineConfig: (data) => null,

      ParquetColumn: (data) => null,

      MappingCategoryIds: (data) => null,

      ConvertedActivityFullRecord: (data) => null,
      ConvertedActivityEfsRecord: (data) => null,
      ConvertedActivityRecord: (data) => null,
      ConversionChainItem: (data) => null,
      PipelineRowResult: (data) => null,
      PipelineRowResultEquation: (data) => null,
      ClimateProgramInfo: () => null,
      ClimateProgramInfoStep: () => null,
      ClimateProgramInfoTarget: () => null,
      FinancialsAccount: () => null,
      IntensityQuantitiesPerMonthPayload: () => null,
      IntensityQuantitiesPerMonth: () => null,

      DuckHuntLeaderboardEntry: (data) => (data as any).hunter.id,

      DependentReportItems: () => null,
      ReportTableColumn: () => null,
      ReportTableRow: () => null,

      IngestionQuestionAnswer: () => null,
      IngestionQuestionResponse: () => null,
      IngestionQuestionOption: () => null,
      IngestionExampleDataRow: () => null,
      IngestionExampleDataColumn: () => null,
      IngestionExampleDataRowField: () => null,

      InstructionsBundle: () => null,
      InstructionsStep: () => null,

      CadtRowsToMapForOrgOutput: (data) => null,
      RawCadtRowToMap: (data) => null,
      RawCadtRowToMapVendor: (data) => null,

      ColumnMapping: () => null,
      UserUploadedTableRoboCleanerResult: () => null,
      UserUploadedTableUserReviewMetadata: () => null,
      FrozenDatasourceSummary: (data) =>
        (data as GQFrozenDatasourceSummary).signedUrl,
      SerializableError: () => null,
      ClimateProgramLifecycle: () => null,

      PipelineResultQuerySummaryRow: () => null,
      PipelineResultQuerySummaryColumnValue: () => null,

      FinanceView: () => null,
      IndustrySector: (data) => (data as GQIndustrySector).sectorCode,
      OrgAdminSuppliers: () => null,
      AdminSuppliers: () => null,
      EmissionsModelEditorEvalPayload: () => null,
      SimpleTimeseries: (data) =>
        (data as Partial<GQSimpleTimeseries>).id ?? null,
      SupplierViewChartOrder: () => null,
      ReferenceDataVersionSlugOutput: () => null,
      EmissionsModelAncestry: () => null,
      EmissionsModelAncestor: () => null,
      UtilitiesMetadataByType: () => null,
      ExtendedBart: () => null,
      PrepublicationTestSuiteExecution: () => null,
      MethodologyTestSuiteValidationResults: () => null,
      ReferenceDataAncestorRelationship: () => null,
      CdpCandidateColumnIdMapping: () => null,
      CdpCandidateQuestionIdMapping: () => null,

      ReportConfigFrameworkMapping: () => 'reportConfigId',
    },
    updates: {
      Mutation: {
        createOrgRole: (result, args, cache, info) => {
          // This causes an ugly full page refresh, but it's better than it
          // looking like it doesn't work
          cache.invalidate({
            __typename: 'Organization',
            id: (args as GQMutationCreateApiKeyArgs).input.orgId,
          });
        },
        deleteRole: (result, args, cache, info) => {
          // This causes an ugly full page refresh, but it's better than it
          // looking like it doesn't work
          if ((result as any).deleteRole.orgId) {
            cache.invalidate({
              __typename: 'Organization',
              id: (result as any).deleteRole.orgId,
            });
          }
        },
        createActivityDataTable(result, args, cache, info) {
          invalidateAllTopLevelQueriesForOrgId(
            cache,
            'activityDataTables',
            (args as GQMutationCreateActivityDataTableArgs).input.orgId
          );
        },
        refreshCarbonRemovalActivityDataTable(result, args, cache, info) {
          invalidateAllTopLevelQueriesForOrgId(
            cache,
            'activityDataTables',
            (args as GQMutationCreateActivityDataTableArgs).input.orgId
          );
        },
        updateActivityDataTable(result, args, cache, info) {
          const input = (args as GQMutationUpdateActivityDataTableArgs).input;
          if (input.deleted) {
            cache.invalidate({
              __typename: 'ActivityDataTable',
              id: input.activityDataTableId,
            });
          }
        },
        addOrgPointOfContact(result, args, cache, info) {
          cache.invalidate({
            __typename: 'Organization',
            id: (args as GQMutationAddOrgPointOfContactArgs).input.orgId,
          });
        },
        deleteOrgPointOfContact(result, args, cache, info) {
          cache.invalidate({
            __typename: 'Organization',
            id: (args as GQMutationDeleteOrgPointOfContactArgs).input.orgId,
          });
        },
        runReportAnswerVerifier(result, args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'reportAnswerVerifierFailures'
          );
        },
        clearAllReportAnswerVerifierFailures(result, args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'reportAnswerVerifierFailures'
          );
        },

        clearReportAnswerVerifierFailures(result, args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'reportAnswerVerifierFailures'
          );
        },

        archivePipelineResult(result, args, cache, info) {
          cache.invalidate({
            __typename: 'PipelineResult',
            id: (args as GQMutationArchivePipelineResultArgs).input.id,
          });
        },
        archiveFootprintSnapshot(result, args, cache, info) {
          cache.invalidate({
            __typename: 'FootprintSnapshot',
            id: (args as GQMutationArchiveFootprintSnapshotArgs).input.id,
          });
        },
        setFootprintVersionKind(result, args, cache, info) {
          cache.invalidate({
            __typename: 'FootprintVersion',
            id: (args as GQMutationSetFootprintVersionKindArgs).input.id,
          });
        },
        createUserAdminVersion(result, args, cache, info) {
          if (info.error === undefined) {
            cache.invalidate({
              __typename: 'Organization',
              id: (args as GQMutationCreateUserAdminVersionArgs).input.orgId,
            });
          }
        },
        removeUserAdminVersion(
          result,
          args: GQRemoveUserAdminVersionMutationVariables,
          cache,
          info
        ) {
          if (info.error === undefined) {
            const user = (result as GQRemoveUserAdminVersionMutation)
              .removeUserAdminVersion?.user;
            if (user) {
              cache.invalidate({
                __typename: 'Organization',
                id: args.input.orgId,
              });
            }
          }
        },
        createCalculationTag(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'calculationTags');
          }
        },
        deleteCalculationTag(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'emissionsModelsStable');
            invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSources');
            invalidateAllTopLevelQueriesForNode(cache, 'calculationTags');
          }
        },
        updateCalculationTag(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'calculationTags');
            invalidateAllTopLevelQueriesForNode(cache, 'emissionsModelsStable');
            invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSources');
          }
        },
        addCalculationTagToStableModel(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'emissionsModelsStable');
          }
        },
        removeCalculationTagFromStableModel(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'emissionsModelsStable');
          }
        },
        addCalculationTagToReferenceData(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSources');
          }
        },
        removeCalculationTagFromReferenceData(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSources');
          }
        },
        findDuck(result, args, cache, info) {
          cache.invalidate('Query', 'duckHuntLeaderboard');
          cache.invalidate('Query', 'ducksForHunter');
          cache.invalidate('Query', 'shouldShowDuck');
        },
        deleteMarketplacePurchase(result, args, cache, info) {
          cache.invalidate('Query', 'marketplacePurchases');
        },
        deleteMarketplaceProject(result, args, cache, info) {
          invalidateAllTopLevelQueriesForNode(cache, 'marketplaceProjects');
          if (!info.error) {
            cache.invalidate('Query', 'marketplaceInternationalEacProjects');
          }
        },
        createMarketplaceProject(result, args, cache, info) {
          invalidateAllTopLevelQueriesForNode(cache, 'marketplaceProjects');
          if (!info.error) {
            cache.invalidate('Query', 'marketplaceInternationalEacProjects');
          }
        },
        createMarketplaceProjectArchetype(result, args, cache, info) {
          if (!info.error) {
            cache.invalidate('Query', 'marketplaceProjectArchetypes');
          }
        },
        createMarketplaceSupplier(result, args, cache, info) {
          if (!info.error) {
            cache.invalidate('Query', 'marketplaceSuppliers');
          }
        },
        createMarketplaceDeveloper(result, args, cache, info) {
          if (!info.error) {
            cache.invalidate('Query', 'marketplaceDevelopers');
          }
        },
        deleteMarketplaceDeveloper(result, args, cache, info) {
          if (!info.error) {
            const query = gql`
              query DeleteMarketplaceDeveloperCacheUpdate {
                marketplaceDevelopers {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            `;
            cache.updateQuery({ query }, (dataUntyped) => {
              // data is null if this data is not yet in the cache
              if (dataUntyped) {
                const data =
                  dataUntyped as GQDeleteMarketplaceDeveloperCacheUpdateQuery;
                const idToDelete = must(
                  (result as GQDeleteMarketplaceDeveloperMutation)
                    .deleteMarketplaceDeveloper
                ).marketplaceDeveloper.id;
                const toDeleteIndex =
                  data.marketplaceDevelopers.edges.findIndex(
                    (edge: any) => edge.node.id === idToDelete
                  );
                data.marketplaceDevelopers.edges = [
                  ...data.marketplaceDevelopers.edges.slice(0, toDeleteIndex),
                  ...data.marketplaceDevelopers.edges.slice(toDeleteIndex + 1),
                ];
                return data;
              }
            });
          }
        },
        createMarketplacePurchase(result, args, cache, info) {
          if (!info.error) {
            cache.invalidate('Query', 'marketplacePurchases');
          }
        },
        createMarketplacePurchaseLineItem(result, args, cache, info) {
          if (!info.error) {
            cache.invalidate('Query', 'marketplacePurchaseLineItems');
          }
        },
        deleteMarketplacePurchaseLineItem(result, args, cache, info) {
          if (!info.error) {
            cache.invalidate('Query', 'marketplacePurchaseLineItems');
          }
        },
        createMarketplaceAllocationInstruction(result, args, cache, info) {
          const lineItemId = (
            args.input as
              | GQCreateMarketplaceAllocationInstructionInput
              | undefined
          )?.purchaseLineItemId;
          if (!info.error && lineItemId) {
            // This refreshes the entire page :/
            cache.invalidate(
              {
                __typename: 'MarketplacePurchaseLineItem',
                id: lineItemId,
              },
              'allocations'
            );
          }
        },
        bulkImportMarketplaceAllocationInstructions(result, args, cache, info) {
          const lineItemIds = (
            args.input as
              | GQBulkImportMarketplaceAllocationInstructionsInput
              | undefined
          )?.instructions.map((instruction) => instruction.purchaseLineItemId);
          if (!info.error && lineItemIds) {
            // This refreshes the entire page :/
            lineItemIds.forEach((lineItemId) => {
              cache.invalidate(
                {
                  __typename: 'MarketplacePurchaseLineItem',
                  id: lineItemId,
                },
                'allocations'
              );
            });
          }
        },
        deleteMarketplaceDocument(result, args, cache, info) {
          const document = (result as GQDeleteMarketplaceDocumentMutation)
            .deleteMarketplaceDocument?.document;
          if (document) {
            const fieldDescriptor = document.purchase
              ? { __typename: 'MarketplacePurchase', id: document.purchase.id }
              : document.purchaseLineItem
                ? {
                    __typename: 'MarketplacePurchaseLineItem',
                    id: document.purchaseLineItem.id,
                  }
                : null;
            if (fieldDescriptor) {
              // Just invalidate all the documents for the purchase or
              // purchaseLineItem.  We _could_ update the cache in-place to
              // prevent a refetch in the UI if we wanted to, but that's a bit
              // more work.
              cache.invalidate(fieldDescriptor, 'documents');
            }
          }
        },
        createDataIssue(result, args, cache, info) {
          invalidateAllTopLevelQueriesForOrgId(cache, 'inboxItemsForOrg', null);
        },
        createApiKey(result, args, cache, info) {
          // This causes an ugly full page refresh, but it's better than it
          // looking like it doesn't work
          cache.invalidate({
            __typename: 'Organization',
            id: (args as GQMutationCreateApiKeyArgs).input.orgId,
          });
        },
        replaceCompany(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'companies');
          invalidateAllTopLevelQueriesForNode(cache, 'company');
        },
        // Company V2
        upsertCompany(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'companies');
        },
        syncFeatureFlags(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'featureFlags');
        },
        createFinancialsReviewItemDrafts(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financialsReviewItemsAdmin'
          );
        },
        deleteFinancialsReviewItem(
          result: GQDeleteFinancialsReviewItemMutation,
          args: GQDeleteFinancialsReviewItemMutationVariables,
          cache,
          info
        ) {
          // TODO(ankitr): DRY with marketplace developers
          if (!info.error) {
            cache.updateQuery(
              {
                query: ReviewItemsDialogDocument,
                variables: { orgId: args.input.orgId },
              },
              (data: GQReviewItemsDialogQuery | null | undefined) => {
                if (data !== null && data !== undefined) {
                  const idToDelete = result.deleteFinancialsReviewItem?.id;
                  const toDeleteIndex =
                    data.financialsReviewItemsAdmin.edges.findIndex(
                      (edge) => must(edge?.node?.id) === idToDelete
                    );
                  data.financialsReviewItemsAdmin.edges = [
                    ...data.financialsReviewItemsAdmin.edges.slice(
                      0,
                      toDeleteIndex
                    ),
                    ...data.financialsReviewItemsAdmin.edges.slice(
                      toDeleteIndex + 1
                    ),
                  ];
                  return data;
                }
              }
            );
          }
        },
        markUserUploadAcknowledged(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'task');
          invalidateAllTopLevelQueriesForNode(cache, 'userUploadTask');
        },
        createCompanyClimateCommitment(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'company');
        },
        deleteCompanyClimateCommitment(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'company');
        },
        updateCompanyClimateCommitment(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'company');
        },
        createPublicDisclosure(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'publicDisclosuresAdmin');
          invalidateAllTopLevelQueriesForNode(cache, 'benchmarks');
          invalidateAllTopLevelQueriesForNode(cache, 'company');
        },
        editPublicDisclosure(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'publicDisclosuresAdmin');
          invalidateAllTopLevelQueriesForNode(cache, 'benchmarks');
          invalidateAllTopLevelQueriesForNode(cache, 'company');
          invalidateAllTopLevelQueriesForNode(cache, 'object');
        },
        deletePublicDisclosure(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'publicDisclosuresAdmin');
          invalidateAllTopLevelQueriesForNode(cache, 'company');
        },
        createExternalReportTypeWithRevision(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'externalReportTypesWithRevision'
          );
        },
        deleteExternalReportTypeWithRevision(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'externalReportTypesWithRevision'
          );
        },
        createExternalReportQuestion(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'externalReportQuestions');
        },
        deleteExternalReportQuestion(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'externalReportQuestions');
        },
        createReportConfig(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'reportConfigs');
        },
        updateReportConfig(
          // Needs manual cache invalidation because question IDs might get reordered
          result: GQUpdateReportConfigFromAdminTableMutation,
          _args,
          cache,
          _info
        ) {
          if (result.updateReportConfig?.reportConfig) {
            cache.invalidate({
              __typename: 'ReportConfig',
              id: result.updateReportConfig.reportConfig.id,
            });
          }
        },
        createReportConfigFromSrc(
          _result,
          args: GQMutationCreateReportConfigFromSrcArgs,
          cache,
          _info
        ) {
          invalidateAllTopLevelQueriesForNode(cache, 'reportConfigs');
          if (args.input.configIdToExtend) {
            cache.invalidate({
              __typename: 'ReportConfig',
              id: args.input.configIdToExtend,
            });
          }
        },
        deleteReportConfig(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'reportConfigs');
        },
        insertReportQuestionIdentifier(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'reportQuestionIdentifiers'
          );
        },
        deleteReportQuestion(
          result: GQDeleteReportQuestionMutation,
          args: GQMutationDeleteReportQuestionArgs,
          cache,
          _info
        ) {
          cache.invalidate({
            __typename: 'ReportQuestion',
            id: args.input.id,
          });
          const parentId = result.deleteReportQuestion?.parentId;
          parentId && invalidateReportConfigObject(cache, parentId);
        },
        createReportQuestionContainer(
          _result,
          args: GQMutationCreateReportQuestionContainerArgs,
          cache,
          _info
        ) {
          const parentId = args.input.parentId;
          parentId && invalidateReportConfigObject(cache, parentId);
        },
        deleteReportQuestionContainer(
          result: GQDeleteReportQuestionContainerMutation,
          args: GQMutationDeleteReportQuestionContainerArgs,
          cache,
          _info
        ) {
          cache.invalidate({
            __typename: 'ReportQuestionContainer',
            id: args.input.id,
          });

          const parentId = result.deleteReportQuestionContainer?.parentId;
          parentId && invalidateReportConfigObject(cache, parentId);
        },
        upsertCdpQuestionMappings(
          result: GQUpsertCdpQuestionMappingsMutation,
          _args,
          cache,
          info
        ) {
          if (!info.error) {
            updateCdpIdMappingsCache({
              candidateMappingsAccessor: 'candidateQuestionMappings',
              savedMappingsAccessor: 'questionIdMappings',
              result: result.upsertCdpQuestionMappings,
              cache,
            });
          }
        },
        upsertCdpColumnMappings(
          result: GQUpsertCdpColumnMappingsMutation,
          _args,
          cache,
          info
        ) {
          if (!info.error) {
            updateCdpIdMappingsCache({
              candidateMappingsAccessor: 'candidateColumnMappings',
              savedMappingsAccessor: 'columnIdMappings',
              result: result.upsertCdpColumnMappings,
              cache,
            });
          }
        },
        editEmissionsYear(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'publicDisclosuresAdmin');
          invalidateAllTopLevelQueriesForNode(cache, 'company');
        },
        deleteEmissionsYear(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'publicDisclosuresAdmin');
          invalidateAllTopLevelQueriesForNode(cache, 'company');
        },
        createMeasurementContextItem(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'measurementContextItems');
        },
        deleteMeasurementContextItem(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'measurementContextItems');
        },
        createFundAdmin(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'orgFunds');
        },
        deleteFunds(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'orgFunds');
        },
        createReferenceDataSource(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSources');
        },
        createCompositeDataSource(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSources');
        },
        createReferenceDataVersion(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataVersion');
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
        },
        createTcfdArchetypeOpportunity(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'tcfdArchetypeOpportunities'
          );
        },
        createTcfdArchetypeRisk(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'tcfdArchetypeRisks');
        },
        deleteTcfdArchetypeRisk(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'tcfdArchetypeRisks');
        },
        updateReferenceDataSource(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
        },
        updateReferenceDataVersion(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataVersion');
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
        },
        publishReferenceDataVersion(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataVersion');
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
        },
        updateReferenceDataRevision(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataRevision');
          // Updating revisions may change the source's linked asumption sources
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
        },
        archiveReferenceDataVersion(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
        },
        archiveReferenceDataRevision(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
        },
        toggleCliqSyncForReferenceDataSource(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
        },
        saveEmissionsModelDraft(_result, args, cache, info) {
          if (info.error) {
            return;
          }
          invalidateAllTopLevelQueriesForNode(
            cache,
            'emissionsModelStableVersionHistory'
          );
          cache.invalidate({
            __typename: 'EmissionsModelVersion',
            id: (args as GQMutationSaveEmissionsModelDraftArgs).input
              .parentEmissionsModelVersionId,
          });
        },
        startPublishingEmissionsModel(_, args, cache, info) {
          if (info.error) {
            return;
          }
          invalidateAllTopLevelQueriesForNode(
            cache,
            'emissionsModelStableVersionHistory'
          );
        },
        createEmissionsModel(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'emissionsModelsStable');
          }
        },
        forkEmissionsModel(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'emissionsModelsStable');
          }
        },
        unlinkEmissionsModelsFromRelease(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(
              cache,
              'emissionsModelsStableForBusinessActivityType'
            );
          }
        },
        createCanonicalClimateProgramProject(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(
              cache,
              'canonicalClimateProgramProjects'
            );
          }
        },
        deleteCanonicalClimateProgramProject(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(
              cache,
              'canonicalClimateProgramProjects'
            );
          }
        },
        deleteMeasurementTestSuiteBart(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'measurementTestSuite');
          }
        },
        swapEmissionsModelExpectationsForTestRows(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'measurementTestSuite');
          }
        },
        swapEmissionsModelExpectationsForFootprintTestRows(
          _result,
          _args,
          cache,
          info
        ) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'measurementTestSuite');
          }
        },
        deleteMeasurementTestSuite(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'measurementTestSuites');
          }
        },
        undeleteMeasurementTestSuite(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'measurementTestSuites');
          }
        },
        invalidateMeasurementTestSuitePage(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(
              cache,
              'footprintTestSuiteExecutionStepResult'
            );
            invalidateAllTopLevelQueriesForNode(
              cache,
              'measurementTestSuiteChangelog'
            );
          }
        },
        duplicateMeasurementTestSuite(result, args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'measurementTestSuites');
          }
        },
        createCompanyRelationship(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'company');
          }
        },
        deleteCompanyRelationship(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'company');
          }
        },
        createReferenceDataCitation(result, args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(
              cache,
              'referenceDataCitations'
            );
          }
        },
        archiveReferenceDataCitation(result, args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(
              cache,
              'referenceDataCitations'
            );
          }
        },
        createEngagementTaskConfigForAdmin(
          result: GQCreateEngagementTaskConfigAdminMutation,
          args,
          cache,
          info
        ) {
          if (!info.error) {
            cache.updateQuery(
              { query: GlobalEngagementTaskConfigsAdminDocument },
              (data: GQGlobalEngagementTaskConfigsAdminQuery | null) => {
                const record =
                  result.createEngagementTaskConfigForAdmin
                    .engagementTaskConfig;
                data?.globalEngagementTaskConfigs.push(record);
                return data;
              }
            );
            const { orgId } =
              args.input as GQGlobalEngagementTaskConfigsAdminQueryVariables;
            cache.updateQuery(
              {
                query: OrgSpecificEngagementTaskConfigsAdminDocument,
                variables: { orgId },
              },
              (data: GQOrgSpecificEngagementTaskConfigsAdminQuery | null) => {
                const record =
                  result.createEngagementTaskConfigForAdmin
                    .engagementTaskConfig;
                data?.engagementTaskConfigs.push(record);
                return data;
              }
            );
          }
        },
        deleteEngagementTaskConfigForAdmin(
          result: GQDeleteEngagementTaskConfigAdminMutation,
          args,
          cache,
          info
        ) {
          if (!info.error) {
            cache.updateQuery(
              { query: GlobalEngagementTaskConfigsAdminDocument },
              (data: GQGlobalEngagementTaskConfigsAdminQuery | null) => {
                if (data) {
                  return {
                    ...data,
                    globalEngagementTaskConfigs:
                      data.globalEngagementTaskConfigs.filter(
                        (etc) =>
                          etc.id !==
                          result.deleteEngagementTaskConfigForAdmin?.id
                      ),
                  };
                }
                return data;
              }
            );
            cache
              .inspectFields('Query')
              .filter((field) => field.fieldName === 'engagementTaskConfigs')
              .forEach((field) => {
                cache.updateQuery(
                  {
                    query: OrgSpecificEngagementTaskConfigsAdminDocument,
                    variables: { ...field.arguments },
                  },
                  (
                    data: GQOrgSpecificEngagementTaskConfigsAdminQuery | null
                  ) => {
                    if (data) {
                      return {
                        ...data,
                        engagementTaskConfigs:
                          data.engagementTaskConfigs.filter(
                            (etc) =>
                              etc.id !==
                              result.deleteEngagementTaskConfigForAdmin?.id
                          ),
                      };
                    }
                    return data;
                  }
                );
              });
          }
        },
        createCts(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'customerTargetSchemas');
          }
        },
        duplicateCts(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'customerTargetSchemas');
          }
        },
        reviewPrepublicationTestResultsForEmissionsModel(
          _result,
          args,
          cache,
          info
        ) {
          if (info.error) return;

          const { acceptResultsAndPublish } =
            args as GQReviewPrepublicationTestResultsInput;
          if (!acceptResultsAndPublish) return;

          invalidateAllTopLevelQueriesForNode(
            cache,
            'emissionsModelStableVersionHistory'
          );
          invalidateAllTopLevelQueriesForNode(
            cache,
            'emissionsModelVersionImportedDescendantsFromDirectImportIds'
          );
          // main editor page is swapped within the EM store.
        },
        reviewPrepublicationTestResultsForReferenceDataRevision(
          _result,
          _args,
          cache,
          info
        ) {
          if (info.error) return;

          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataSource');
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataRevision');
          invalidateAllTopLevelQueriesForNode(cache, 'referenceDataVersion');
        },
        updateSuppliersSettingsAdmin(_result, _args, cache) {
          invalidateAllTopLevelQueriesForNode(cache, 'suppliersSettingsAdmin');
        },
        reviewPrepublicationTestResultsForRelease(_result, _args, cache, info) {
          if (info.error) return;

          invalidateAllTopLevelQueriesForNode(cache, 'emissionsModelRelease');
        },
      },
    },
    schema,
  });

export default cache;

interface CdpQuestionMappingParams {
  candidateMappingsAccessor: 'candidateQuestionMappings';
  savedMappingsAccessor: 'questionIdMappings';
  result:
    | GQUpsertCdpQuestionMappingsMutation['upsertCdpQuestionMappings']
    | null;
  cache: Cache;
}

interface CdpColumnMappingParams {
  candidateMappingsAccessor: 'candidateColumnMappings';
  savedMappingsAccessor: 'columnIdMappings';
  result: GQUpsertCdpColumnMappingsMutation['upsertCdpColumnMappings'] | null;
  cache: Cache;
}

function isCdpMappingNonNull<T extends { cdpRefId: string | null }>(
  mapping: T
): mapping is T & { cdpRefId: string } {
  return mapping.cdpRefId !== null;
}

/**
 * Helper function to update relevant CDP ID mapping caches when we mutate state.
 *
 * We follow a similar pattern for question and column mappings so encapsulate the logic
 * in one place here.
 */
function updateCdpIdMappingsCache<
  T extends CdpQuestionMappingParams | CdpColumnMappingParams,
>(params: T): void {
  const { candidateMappingsAccessor, savedMappingsAccessor, result, cache } =
    params;

  // For any mappings that were succesfully saved:
  // 1. Remove them from the cdpMissingIdMappings.candidate*Mappings.
  // 2. Replace or add them in the cdpIdMappings.*IdMappings.
  // We filter out all nullish mappings here because we never have candidate mappings
  // that give null cdpRefIds.
  const newMappings: Array<{ cdpRefId: string | null }> | undefined =
    result?.updatedMappings;
  if (!newMappings) {
    return;
  }

  const nonNullNewMappingsByRefId: Record<string, { cdpRefId: string }> = keyBy(
    newMappings.filter(isCdpMappingNonNull),
    ({ cdpRefId }) => cdpRefId
  );

  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'cdpMissingIdMappings')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetCdpIdMappingsDocument,
          variables: { ...field.arguments },
        },
        (data: GQGetCdpIdMappingsQuery | null) => {
          if (data && data.cdpMissingIdMappings) {
            const oldCandidateIdMappings =
              data.cdpMissingIdMappings[candidateMappingsAccessor];
            const newCandidateIdMappings = oldCandidateIdMappings.filter(
              (oldMapping) => {
                const addedMapping =
                  nonNullNewMappingsByRefId[oldMapping.cdpRefId];
                // Keep the candidates that we haven't successfully added.
                return !addedMapping;
              }
            );

            return {
              ...data,
              cdpMissingIdMappings: {
                ...data.cdpMissingIdMappings,
                [candidateMappingsAccessor]: newCandidateIdMappings,
              },
            };
          }
          return data;
        }
      );
    });

  cache.updateQuery(
    { query: GetCdpIdMappingsDocument },
    (data: GQGetCdpIdMappingsQuery | null) => {
      if (data) {
        const updatedRefIds: Array<string> = [];
        const oldIdMappings =
          data.cdpIdMappings[savedMappingsAccessor].filter(isCdpMappingNonNull);
        const newIdMappings = oldIdMappings.map((oldMapping) => {
          const newMapping = nonNullNewMappingsByRefId[oldMapping.cdpRefId];
          if (newMapping) {
            updatedRefIds.push(newMapping.cdpRefId);
            return newMapping;
          } else {
            return oldMapping;
          }
        });
        newIdMappings.push(
          ...newMappings
            .filter(
              ({ cdpRefId }) =>
                cdpRefId && updatedRefIds.indexOf(cdpRefId) === -1
            )
            .filter(isCdpMappingNonNull)
        );

        return {
          ...data,
          cdpIdMappings: {
            ...data.cdpIdMappings,
            [savedMappingsAccessor]: newIdMappings,
          },
        };
      }
      return data;
    }
  );
}
