import { BadInputError } from '../errors/BadInputError';

export const EMISSIONS_KIND_IDS = [
  'emissions',
  'emissions_location',
  'biogenic_co2',
  'biogenic_co2_location',
  'clean_power_avoided_emissions',
] as const;

export type EmissionsKindId = (typeof EMISSIONS_KIND_IDS)[number];

type EmissionsKindBaseDefinition = {
  id: EmissionsKindId;
  displayName: string;
  description: string;
  unit: string;
  // sql columns representing this kind in PLR / melted evaluation
  sqlQuantityField: string;
  sqlNamespacedVariableField: string;
  sqlEmissionsFactorField: string | null;
};

type GHGEmissionsKindMetadata = {
  typeId: 'ghg';
  isBiogenic: boolean;
} & (
  | {
      isLocationBased: true;
      marketBasedEmissionsKind: EmissionsKindId;
    }
  | {
      isLocationBased: false;
    }
);

type SimpleEmissionsKind = EmissionsKindBaseDefinition & {
  typeId: 'simple';
};
type GHGEmissionsKind = GHGEmissionsKindMetadata & EmissionsKindBaseDefinition;

export type EmissionsKindDefinition = SimpleEmissionsKind | GHGEmissionsKind;

const definitions: Array<EmissionsKindDefinition> = [
  {
    typeId: 'ghg',
    id: 'emissions',
    unit: 'kgco2e',
    displayName: 'Emissions',
    description:
      'Emissions for the activity. For electricity consumption, this represents the market-based allocation method.',
    sqlQuantityField: 'quantity_kg_co2e',
    sqlNamespacedVariableField: 'emissions_model_namespaced_variable_name',
    sqlEmissionsFactorField: 'ef_kg_co2e',
    isBiogenic: false,
    isLocationBased: false,
  },
  {
    typeId: 'ghg',
    id: 'emissions_location',
    unit: 'kgco2e',
    displayName: 'Location-based electricity emissions',
    description: 'Location-based emissions for electricity consumption.',
    sqlQuantityField: 'allocation_location_quantity_kg_co2e',
    sqlNamespacedVariableField:
      'allocation_location_emissions_model_namespaced_variable_name',
    sqlEmissionsFactorField: null,
    isBiogenic: false,
    isLocationBased: true,
    marketBasedEmissionsKind: 'emissions',
  },
  {
    typeId: 'ghg',
    id: 'biogenic_co2',
    unit: 'kgco2e',
    displayName: 'Biogenic CO₂ emissions',
    description:
      'Biogenic CO₂ emissions for the activity. For electricity consumption, this represents the market-based allocation method.',
    sqlQuantityField: 'biogenic_co2_quantity_kg_co2e',
    sqlNamespacedVariableField:
      'biogenic_co2_emissions_model_namespaced_variable_name',
    sqlEmissionsFactorField: null,
    isBiogenic: true,
    isLocationBased: false,
  },
  {
    typeId: 'ghg',
    id: 'biogenic_co2_location',
    unit: 'kgco2e',
    displayName: 'Location-based Biogenic CO₂ emissions',
    description:
      'Biogenic CO₂ emissions for the activity using the location-based allocation method.',
    sqlQuantityField: 'biogenic_co2_location_quantity_kg_co2e',
    sqlNamespacedVariableField:
      'biogenic_co2_location_emissions_model_namespaced_variable_name',
    sqlEmissionsFactorField: null,
    isBiogenic: true,
    isLocationBased: true,
    marketBasedEmissionsKind: 'biogenic_co2',
  },
  {
    typeId: 'simple',
    id: 'clean_power_avoided_emissions',
    unit: 'kgco2e',
    displayName: 'Clean power avoided emissions',
    description: 'The emissions avoided by using clean power.',
    sqlQuantityField: 'clean_power_avoided_quantity_kg_co2e',
    sqlNamespacedVariableField:
      'clean_power_avoided_emissions_model_namespaced_variable_name',
    sqlEmissionsFactorField: null,
  },
];

const definitionsMap: Map<EmissionsKindId, EmissionsKindDefinition> = new Map();
definitions.forEach((emissionsKindDef) =>
  definitionsMap.set(emissionsKindDef.id, emissionsKindDef)
);

/**
 * Gets the definition of an emissions kind, throwing an error if the kind
 * doesn't exist.
 */
export function getEmissionsKindDefinition(
  id: EmissionsKindId
): EmissionsKindDefinition {
  const def = definitionsMap.get(id);
  if (!def) {
    throw new BadInputError(`No emissions kind named "${id}"`);
  }
  return def;
}

export function getAllEmissionsKindDefinitions(): Array<EmissionsKindDefinition> {
  return definitions;
}

export function isGHGEmissionsKind(
  emissionsKind: EmissionsKindDefinition
): emissionsKind is GHGEmissionsKind {
  return 'isLocationBased' in emissionsKind;
}
